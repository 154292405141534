<app-header></app-header>
<app-banner></app-banner>
<app-info></app-info>
<app-about-us></app-about-us>
<app-services></app-services>
<app-map></app-map>
<app-prices></app-prices>
<app-gallery></app-gallery>
<app-contact></app-contact>
<!-- Footer-->
<footer class="bg-light py-5">
  <div class="container px-4 px-lg-5">
    <div class="small text-center text-muted">Copyright &copy; 2023 - APULIA SURF SCHOOL</div>
  </div>
</footer>

<div class="modal fade" id="reservationModal" tabindex="-1" aria- labelledby="reservationModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-lg modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title " id="reservationModalLabel">{{ languageStoreService.Texts.reservationForum.title
          }}</h5>
        <button type="button" id="closeModal" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body text-danger">
        <form [formGroup]="angForm" (ngSubmit)="onSubmitForm()">
          <div class="d-sm-none d-md-none d-lg-block d-xl-block">
          <div class="row">
            <div class="col-6">
              <div class="form-floating mb-3">
                <input class="form-control custom-font" formControlName="name" type="text" />
                <label for="name">{{ languageStoreService.Texts.reservationForum.field1 }}</label>
                <div class="invalid-feedback" *ngIf="angForm.controls?.['name']?.status === 'INVALID' && isButtonClicked">
                  {{languageStoreService.Texts.reservationForum.field1 }} is required.</div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-floating mb-3">
                <input class="form-control custom-font" formControlName="email" type="text" />
                <label for="name">{{ languageStoreService.Texts.reservationForum.field2 }}</label>
                <div class="invalid-feedback" *ngIf="angForm.controls?.['email']?.status === 'INVALID'  && isButtonClicked">
                  {{
                  languageStoreService.Texts.reservationForum.field2 }} is required.</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              <div class="form-floating mb-3">
                  <select class="form-control" formControlName="phoneIndicator" data-sb-validations="required" >
                    <option *ngFor="let item of listPhoneIndicators" [value]="item.dial_code"  >{{item.code}}  {{item.emoji}}  {{item.dial_code}}</option>
                  </select>
                  <label for="name">Indicador</label>
              </div>
            </div>
            <div class="col-4">
              <div class="form-floating mb-3">
                <input class="form-control" formControlName="phone" type="text" data-sb-validations="required" />
                <label for="name">{{ languageStoreService.Texts.reservationForum.field3 }}</label>
                <div class="invalid-feedback" *ngIf="angForm.controls?.['phone']?.status === 'INVALID'  && isButtonClicked">
                  {{
                  languageStoreService.Texts.reservationForum.field3 }} is required.</div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-floating mb-3">
                <input class="form-control" formControlName="classDate" min="{{ minDate }}" id="name" type="date"
                  data-sb-validations="required" />
                <label for="name">{{ languageStoreService.Texts.reservationForum.field4 }}</label>
                <div class="invalid-feedback"
                  *ngIf="angForm.controls?.['classDate']?.status === 'INVALID' && isButtonClicked">
                  {{
                  languageStoreService.Texts.reservationForum.field4 }} is required.</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-floating mb-3">
                <select class="form-control" formControlName="type" formControlName="type">
                  <optgroup *ngFor="let type of languageStoreService.Texts.reservationForum.types" label="{{ type.type }}">
                    <option *ngFor="let value of type.values" [value]="convertJSON(value)">
                      {{ value.type }}
                    </option>
                  </optgroup>
                </select>
                <label for="name">{{ languageStoreService.Texts.reservationForum.field5 }}</label>
                <div class="invalid-feedback" *ngIf="angForm.controls?.['type']?.status === 'INVALID' && isButtonClicked">
                  {{
                  languageStoreService.Texts.reservationForum.field5 }} is required.</div>

              </div>
            </div>
            <div class="col-6">
              <div class="form-floating mb-3">
                <div class="form-control" style="border: none;">
                  <div class="row">
                    <div class="col-6">
                      <input type="radio" id="morning" name="hour" value="morning" checked
                        (change)="changeTime($event)" />
                      <label for="morning">{{
                        languageStoreService.Texts.reservationForum.time.morning
                        }}</label>
                    </div>
                    <div class="col-6">
                      <input type="radio" id="afternoon" name="hour" value="afternoon" (change)="changeTime($event)" />
                      <label for="afternoon">{{
                        languageStoreService.Texts.reservationForum.time.afternoon
                        }}</label>
                    </div>
                  </div>
                </div>
                <label for="name">{{ languageStoreService.Texts.reservationForum.field6 }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-floating mb-3">
                <input class="form-control" type="text" formControlName="comments" />
                <label for="name">{{ languageStoreService.Texts.reservationForum.field7 }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-floating mb-3">
                <div class="d-grid">
                  <button type="button" class="btn btn-primary btn-xl" type="submit" [disabled]="isLoading">
                    {{languageStoreService.Texts.reservationForum.btn }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-sm-block d-md-block d-lg-none d-xl-none">
          <div class="row">
            <div class="col-12">
              <div class="form-floating mb-3">
                <input class="form-control custom-font" formControlName="name" type="text" />
                <label for="name">{{ languageStoreService.Texts.reservationForum.field1 }}</label>
                <div class="invalid-feedback" *ngIf="angForm.controls?.['name']?.status === 'INVALID' && isButtonClicked">
                  {{languageStoreService.Texts.reservationForum.field1 }} is required.</div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-floating mb-3">
                <input class="form-control custom-font" formControlName="email" type="text" />
                <label for="name">{{ languageStoreService.Texts.reservationForum.field2 }}</label>
                <div class="invalid-feedback" *ngIf="angForm.controls?.['email']?.status === 'INVALID'  && isButtonClicked">
                  {{
                  languageStoreService.Texts.reservationForum.field2 }} is required.</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <div class="form-floating mb-3">
                  <select class="form-control" formControlName="phoneIndicator" data-sb-validations="required" >
                    <option *ngFor="let item of listPhoneIndicators" [value]="item.dial_code"  >{{item.code}}  {{item.emoji}}  {{item.dial_code}}</option>
                  </select>
                  <label for="name">Indicador</label>
              </div>
            </div>
            <div class="col-8">
              <div class="form-floating mb-3">
                <input class="form-control custom-font" formControlName="phone" type="text" data-sb-validations="required" />
                <label for="name">{{ languageStoreService.Texts.reservationForum.field3 }}</label>
                <div class="invalid-feedback" *ngIf="angForm.controls?.['phone']?.status === 'INVALID'  && isButtonClicked">
                  {{
                  languageStoreService.Texts.reservationForum.field3 }} is required.</div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-floating mb-3">
                <input class="form-control custom-font" formControlName="classDate" min="{{ minDate }}" id="name" type="date"
                  data-sb-validations="required" />
                <label for="name">{{ languageStoreService.Texts.reservationForum.field4 }}</label>
                <div class="invalid-feedback"
                  *ngIf="angForm.controls?.['classDate']?.status === 'INVALID' && isButtonClicked">
                  {{
                  languageStoreService.Texts.reservationForum.field4 }} is required.</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-floating mb-3">
                <select class="form-control custom-font" formControlName="type" formControlName="type">
                  <optgroup *ngFor="let type of languageStoreService.Texts.reservationForum.types" label="{{ type.type }}">
                    <option *ngFor="let value of type.values" [value]="convertJSON(value)">
                      {{ value.type }}
                    </option>
                  </optgroup>
                </select>
                <label for="name">{{ languageStoreService.Texts.reservationForum.field5 }}</label>
                <div class="invalid-feedback" *ngIf="angForm.controls?.['type']?.status === 'INVALID' && isButtonClicked">
                  {{
                  languageStoreService.Texts.reservationForum.field5 }} is required.</div>

              </div>
            </div>
            <div class="col-12">
              <div class="form-floating mb-3">
                <div class="form-control" style="border: none;">
                  <div class="row">
                    <div class="col-6">
                      <input type="radio" id="morning" name="hour" value="morning" checked
                        (change)="changeTime($event)" />
                      <label for="morning">{{
                        languageStoreService.Texts.reservationForum.time.morning
                        }}</label>
                    </div>
                    <div class="col-6">
                      <input type="radio" id="afternoon" name="hour" value="afternoon" (change)="changeTime($event)" />
                      <label for="afternoon">{{
                        languageStoreService.Texts.reservationForum.time.afternoon
                        }}</label>
                    </div>
                  </div>
                </div>
                <label for="name">{{ languageStoreService.Texts.reservationForum.field6 }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-floating mb-3">
                <input class="form-control custom-font" type="text" formControlName="comments" />
                <label for="name">{{ languageStoreService.Texts.reservationForum.field7 }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-floating mb-3">
                <div class="d-grid">
                  <button type="button" class="btn btn-primary btn-xl" type="submit" [disabled]="isLoading">
                    {{languageStoreService.Texts.reservationForum.btn }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="alert alert-success alert-dismissible fade show  d-sm-block d-md-block d-lg-none d-xl-none" role="alert"
  *ngIf="showAlertSuccess" style="position: fixed;top: 131px;width: 95%;z-index: 99999;">
  <strong>{{languageStoreService.Texts.config.success.title }}:</strong> {{languageStoreService.Texts.config.success.text
  }}
</div>

<div class="alert alert-danger alert-dismissible fade show  d-sm-block d-md-block d-lg-none d-xl-none" role="alert"
  *ngIf="showAlertError" style="position: fixed;top: 131px;width: 95%;z-index: 99999;">
  <strong>{{languageStoreService.Texts.config.error.title }}:</strong> {{languageStoreService.Texts.config.error.text }}
</div>


<div class="alert alert-success alert-dismissible fade show d-sm-none d-md-none d-lg-block d-xl-block" role="alert"
  *ngIf="showAlertSuccess" style="position: fixed;top: 131px;left: 62%;z-index: 99999;">
  <strong>{{languageStoreService.Texts.config.success.title }}:</strong> {{languageStoreService.Texts.config.success.text
  }}
</div>

<div class="alert alert-danger alert-dismissible fade show d-sm-none d-md-none d-lg-block d-xl-block" role="alert"
  *ngIf="showAlertError" style="position: fixed;top: 131px;left: 62%;z-index: 99999;">
  <strong>{{languageStoreService.Texts.config.error.title }}:</strong> {{languageStoreService.Texts.config.error.text }}
</div>

<div class="container">
  <div class="whatsapp-main ">
    <div type="floating" class="whatsapp-wrapper hide-whatsapp" style="width: 360px">
      <div color="rgb(29, 33, 41)" role="button" tabindex="0" class="close_whatsapp"></div>
      <div class="whatsapp-header">
        <div size="52" class="support-avatar">
          <div class="avatar-wrapper">
            <div class="avatar-img" style='background: url("{{ languageStoreService.Texts.navbar.logo }}")'></div>
          </div>
        </div>
        <div class="header-info">
          <div class="whatsapp-name">Apúlia Surf School</div>
          <div class="whatsapp-reply-time">
            Usually replies in a few hours
          </div>
        </div>
      </div>
      <div class="whatsapp-background">
        <div class="whatsapp-message-container">
          <div class="loading-animation">
            <div class="dot-pulse"></div>
          </div>
          <div class="whatsapp-message-wrapper">
            <div class="chat-body-wrapper">
              <div class="whatsapp-chat-name">Apúlia Surf School</div>
              <div class="whatsapp-chat-message">
                <p>Hey there 👋</p>
                <br />
                <p>
                  How can we help? 🤓
                </p>
              </div>
              <div class="whatsapp-chat-time">20:49</div>
            </div>
          </div>
        </div>
      </div>
      <a role="button" href="https://api.whatsapp.com/send?phone={{languageStoreService.Texts.config.phoneNumber}}"
        title="WhatsApp" class="whatsapp-btn" target="_blank">
        <svg class="whatsapp-send-icon-small" xmlns="http://www.w3.org/2000/svg" version="1" height="20"
          viewBox="0 0 90 90">
          <path
            d="M90 44a44 44 0 0 1-66 38L0 90l8-24A44 44 0 0 1 46 0c24 0 44 20 44 44zM46 7C25 7 9 24 9 44c0 8 2 15 7 21l-5 14 14-4a37 37 0 0 0 58-31C83 24 66 7 46 7zm22 47l-2-1-7-4-3 1-3 4h-3c-1 0-4-1-8-5-3-3-6-6-6-8v-2l2-2 1-1v-2l-4-8c0-2-1-2-2-2h-2l-3 1c-1 1-4 4-4 9s4 11 5 11c0 1 7 12 18 16 11 5 11 3 13 3s7-2 7-5l1-5z"
            fill="#FFF" />
        </svg>
        <span class="chat-button-text">Start Chat</span>
      </a>
      <div style="
              display: flex;
              justify-content: center;
              align-items: center;
            "></div>
    </div>
    <div class="whatsapp-popup-button" id="whatsapp-popup">
      <div class="whatsapp-notify"></div>
      <div class="whatsapp-button-main">
        <svg class="whatsapp-send-icon-big" xmlns="http://www.w3.org/2000/svg" version="1" width="32" height="32"
          viewBox="0 0 90 90">
          <path
            d="M90 44a44 44 0 0 1-66 38L0 90l8-24A44 44 0 0 1 46 0c24 0 44 20 44 44zM46 7C25 7 9 24 9 44c0 8 2 15 7 21l-5 14 14-4a37 37 0 0 0 58-31C83 24 66 7 46 7zm22 47l-2-1-7-4-3 1-3 4h-3c-1 0-4-1-8-5-3-3-6-6-6-8v-2l2-2 1-1v-2l-4-8c0-2-1-2-2-2h-2l-3 1c-1 1-4 4-4 9s4 11 5 11c0 1 7 12 18 16 11 5 11 3 13 3s7-2 7-5l1-5z"
            fill="#FFF" />
        </svg>
      </div>
    </div>
  </div>
</div>
