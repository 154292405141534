export class Email {
    constructor(Email: string, Body: string, Name: string) {
        this.Email = Email;
        this.Body = Body;
        this.Name = Name;
    }
    Email!: string;
    Body!: string;
    Name!: string;
}


