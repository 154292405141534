<section class="page-section bg-primary" id="about">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 mt-md-6 d-sm-none d-md-none d-lg-block d-xl-block">
                <h1 style="letter-spacing: 0.25em;text-align:center"> {{ languageStoreService.Texts.about.title }}</h1>
                <p class="mb-4 mt-md-5">
                    {{ languageStoreService.Texts.about.text1 }}
                </p>
                <p class="mb-4 mt-md-2">
                    {{ languageStoreService.Texts.about.text2 }}
                </p>
                <div class="align-items-center justify-content-center text-center ">
                    <img class="navbar-brand mt-md-4" [src]="languageStoreService.Texts.about.logo" width="110"
                        height="130">
                </div>
            </div>
            <div class="col-lg-5 d-sm-block d-md-block d-lg-none d-xl-none">
            
                <h1 style="letter-spacing: 0.25em;text-align:center"> {{ languageStoreService.Texts.about.title }}</h1>
                <div class="align-items-center justify-content-center text-center ">
                    <img class="navbar-brand mt-4" [src]="languageStoreService.Texts.about.logo" width="110"
                        height="130">
                </div>
                <p class="mb-4 mt-4 text-sm-center">
                    {{ languageStoreService.Texts.about.text1 }}
                </p>
                <p class="mb-4 mt-2 text-sm-center d-sm-none d-md-none d-lg-block d-xl-block">
                    {{ languageStoreService.Texts.about.text2 }}
                </p>
               
            </div>
            <div class="col-lg-4">
                <div [ngStyle]="{'background-image': 'url(' + languageStoreService.Texts.about.image1 + ')'}"
                    class="imageBox1">
                </div>
            </div>
            <div class="col-lg-3 mt-md-5  d-sm-none d-md-none d-lg-block d-xl-block">
                <div [ngStyle]="{'background-image': 'url(' + languageStoreService.Texts.about.image2 + ')'}"
                    class="imageBox2">
                </div>
            </div>
            <div class="col-12 mt-md-5  d-sm-block d-md-block d-lg-none d-xl-none">
                <p class="mb-4 mt-2 text-center ">
                    {{ languageStoreService.Texts.about.text2 }}
                </p>
            </div>
           
        </div>
        <div class="row mt-5  d-sm-none d-md-none d-lg-block d-xl-block">
            <div class="col-lg-12">
                <div class="text-center" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsedAbout"
                    role="button" aria-expanded="false" aria-controls="collapseAboutUs">
                    <div class="project-name mt-md-5 text-black-50 fs-5"> {{ languageStoreService.Texts.about.btn }}
                    </div>
                    <div class="jumping">
                        <i class="bi bi-chevron-down text-black-50 fs-5"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="collapse multi-collapse" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsedAbout">
                    <div class="row mt-md-4 mt-sm-5">
                        <div class="col">
                            <h3 class="mb-sm-3" style="letter-spacing: 0.25em;text-align:center"> {{
                                languageStoreService.Texts.about2.title }}</h3>
                            <div class="project-name text-black-50 " style="letter-spacing: 0.25em;text-align:center">{{
                                languageStoreService.Texts.about2.text }}</div>
                        </div>
                    </div>
                    <div class="row mt-md-5">
                        <div class="col-md-1">
                        </div>
                        <div class="col-2 col-sm-2 col-md-6">
                            <div class="row">
                                <div class="col-lg-12 text-center">
                                    <div class="mt-5">
                                        <div class="mb-2">
                                            <i [ngClass]="languageStoreService.Texts.about2.service1.icon "
                                                class="text-primary  fs-1 "></i>
                                        </div>
                                        <h4 class="h4 mb-2">{{
                                            languageStoreService.Texts.about2.service1.text }}</h4>
                                        <h3 class="text-muted mb-0">{{
                                            languageStoreService.Texts.about2.service1.total }}</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 text-center">
                                    <div class="mt-5">
                                        <div class="mb-2">
                                            <i [ngClass]="languageStoreService.Texts.about2.service2.icon "
                                                class="text-primary  fs-1 "></i>
                                        </div>
                                        <h4 class="h4 mb-2">{{
                                            languageStoreService.Texts.about2.service2.text }}</h4>
                                        <h3 class="text-muted mb-0">{{
                                            languageStoreService.Texts.about2.service2.total }}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 d-sm-none d-md-none d-lg-block d-xl-block">
                            <div class="align-items-center justify-content-center text-center ">
                                <div [ngStyle]="{'background-image': 'url(' + languageStoreService.Texts.about2.image + ')'}"
                                    class="imageBox3">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="row">
                                <div class="col-lg-12 text-center">
                                    <div class="mt-5">
                                        <div class="mb-2">
                                            <i [ngClass]="languageStoreService.Texts.about2.service3.icon "
                                                class="text-primary  fs-1 "></i>
                                        </div>
                                        <h4 class="h4 mb-2">{{
                                            languageStoreService.Texts.about2.service3.text }}</h4>
                                        <h3 class="text-muted mb-0">{{
                                            languageStoreService.Texts.about2.service3.total }}</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 text-center">
                                    <div class="mt-5">
                                        <div class="mb-2">
                                            <i [ngClass]="languageStoreService.Texts.about2.service4.icon "
                                                class="text-primary  fs-1 "></i>
                                        </div>
                                        <h4 class="h4 mb-2">{{
                                            languageStoreService.Texts.about2.service4.text }}</h4>
                                        <h3 class="text-muted mb-0">{{
                                            languageStoreService.Texts.about2.service4.total }}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>