import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConsoleOverrideService {
  constructor() {
    if (environment.production) {
      this.disableConsole();
    }
  }

  private disableConsole() {
    console.log = () => {};
    console.warn = () => {};
    console.error = () => {};
    console.info = () => {};
    // You can also disable other console methods if needed
  }
}
