<section class="page-section py-0" id="services">
    <div class="d-sm-none d-md-none d-lg-block d-xl-block">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-lg-3 imageBox1"
                        [ngStyle]="{'background-image': 'url(' + languageStoreService.Texts.services.line1[0].image + ')'}">
                    </div>
                    <div class="col-lg-3 services-box-caption align-items-center justify-content-center text-center">
                        <div class="project-name fs-3 py-2"> {{ languageStoreService.Texts.services.line1[0].title }}
                        </div>
                        <p class="text-muted mb-0">{{ languageStoreService.Texts.services.line1[0].text }}</p>
                        <div class="row">
                            <div class="col mt-md-5">
                                <div class="btn-wave hovercursor">
                                    <a data-bs-toggle="modal" data-bs-target="#reservationModal">
                                        <span>{{ languageStoreService.Texts.services.line1[0].btn }}</span>
                                        <div class="wave"></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 imageBox1"
                        [ngStyle]="{'background-image': 'url(' + languageStoreService.Texts.services.line1[1].image + ')'}">
                    </div>
                    <div class="col-lg-3 services-box-caption align-items-center justify-content-center text-center">
                        <div class="project-name fs-3 py-2"> {{ languageStoreService.Texts.services.line1[1].title }}
                        </div>
                        <p class="text-muted mb-0">{{ languageStoreService.Texts.services.line1[1].text }}</p>
                        <div class="row">
                            <div class="col mt-md-5">
                                <div class="btn-wave hovercursor">
                                    <a data-bs-toggle="modal" data-bs-target="#reservationModal">
                                        <span>{{ languageStoreService.Texts.services.line1[1].btn }}</span>
                                        <div class="wave"></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="row">
                    <div class="col-lg-3 services-box-caption align-items-center justify-content-center text-center">
                        <div class="project-name fs-3 py-2"> {{ languageStoreService.Texts.services.line2[0].title }}
                        </div>
                        <p class="text-muted mb-0">{{ languageStoreService.Texts.services.line2[0].text }}</p>
                        <div class="row">
                            <div class="col mt-md-5">
                                <div class="btn-wave hovercursor">
                                    <a data-bs-toggle="modal" data-bs-target="#reservationModal">
                                        <span>{{ languageStoreService.Texts.services.line2[0].btn }}</span>
                                        <div class="wave"></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 imageBox1"
                        [ngStyle]="{'background-image': 'url(' + languageStoreService.Texts.services.line2[0].image + ')'}">
                    </div>
                    <div class="col-lg-3 services-box-caption align-items-center justify-content-center text-center">
                        <div class="project-name fs-3 py-2"> {{ languageStoreService.Texts.services.line2[1].title }}
                        </div>
                        <p class="text-muted mb-0">{{ languageStoreService.Texts.services.line2[1].text }}</p>
                        <div class="row">
                            <div class="col mt-md-5">
                                <div class="btn-wave hovercursor">
                                    <a data-bs-toggle="modal" data-bs-target="#reservationModal">
                                        <span>{{ languageStoreService.Texts.services.line2[1].btn }}</span>
                                        <div class="wave"></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 imageBox1"
                        [ngStyle]="{'background-image': 'url(' + languageStoreService.Texts.services.line2[1].image + ')'}">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row g-0">
            <div class="col-12" *ngFor="let value of languageStoreService.Texts.services.line1">
                <a class="portfolio-box d-sm-block d-md-block d-lg-none d-xl-none mt-3" [ngStyle]="{'background-image': 'url(' + value.image + ')'}"  data-bs-toggle="modal" data-bs-target="#reservationModal">
                    <div class="portfolio-box-caption"
                        style="opacity: 1;align-items: center;background-color: #6697a47a;justify-content: center;padding-top: 25px;">
                        <div class="project-name fs-1 py-2">{{value.title}}</div>
                        <div class="row">
                            <div class="col mt-md-5">
                                <div class="btn-wave hovercursor">
                                    <a data-bs-toggle="modal" data-bs-target="#reservationModal">
                                        <span>{{ languageStoreService.Texts.services.line1[1].btn }}</span>
                                        <div class="wave"></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-12" *ngFor="let value of languageStoreService.Texts.services.line2">
                <a class="portfolio-box d-sm-block d-md-block d-lg-none d-xl-none mt-3" [ngStyle]="{'background-image': 'url(' + value.image + ')'}"  data-bs-toggle="modal" data-bs-target="#reservationModal">
                    <div class="portfolio-box-caption"
                        style="opacity: 1;align-items: center;background-color: #6697a47a;justify-content: center;padding-top: 25px;">
                        <div class="project-name fs-1 py-2">{{value.title}}</div>
                        <div class="row">
                            <div class="col mt-md-5">
                                <div class="btn-wave hovercursor">
                                    <a data-bs-toggle="modal" data-bs-target="#reservationModal">
                                        <span>{{ languageStoreService.Texts.services.line1[1].btn }}</span>
                                        <div class="wave"></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>