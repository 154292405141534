<section class="page-section py-0 mb-md-5" id="gallery">
    <div>
        <div class="row">
            <div class="col-lg-5 align-items-center justify-content-center text-center d-sm-none d-md-none d-lg-block d-xl-block"
                style="align-self: center;">
                <i class="bi bi-instagram " style="font-size: 100px;"></i>
            </div>
            <a class="mb-4 d-sm-block d-md-block d-lg-none d-xl-none"
                style="letter-spacing: 0.25em;text-align:center" href="https://www.instagram.com/apuliasurfschool/" target="_blank"><i class="bi bi-at"></i>apuliasurfschool</a>

            <div class="col-lg-7 d-sm-none d-md-none d-lg-block d-xl-block">
                <div class="mb-md-2" style="display: flex;flex-wrap: wrap;">
                    <div class="col-6" style="height: 300px;background-image: url(../../../assets/images2/gallery/image1.jpeg);background-size: cover;background-position: center;">
                    </div>
                    <div class="col-6" style=" width: 415px;height: 300px;background-image: url(../../../assets/images2/gallery/image2.jpeg);background-size: cover;background-position: center;">
                    </div>
                </div>
                <div style="display: flex;flex-wrap: wrap;margin-top: -8px;">
                    <div class="col-6" style="height: 300px;background-image: url(../../../assets/images2/gallery/image3.jpeg);background-size: cover;background-position: center;">
                    </div>
                    <div class="col-6" style="width: 415px; height: 300px;background-image: url(../../../assets/images2/gallery/image4.jpeg);background-size: cover;background-position: center;">
                    </div>
                </div>
            </div>
            <div class="col-lg-12 d-sm-block d-md-block d-lg-none d-xl-none">
                <div class="mb-md-2" style="display: flex;flex-wrap: wrap;">
                    <div class="col-6" style="height: 100%;background-image: url(../../../assets/images2/gallery/image1.jpeg);background-size: cover;background-position: center;">
                    </div>
                    <div class="col-6" style=" width: 100%;height: 300px;background-image: url(../../../assets/images2/gallery/image2.jpeg);background-size: cover;background-position: center;">
                    </div>
                </div>
                <div style="display: flex;flex-wrap: wrap;margin-top: -8px;">
                    <div class="col-6" style="height: 100%;background-image: url(../../../assets/images2/gallery/image3.jpeg);background-size: cover;background-position: center;">
                    </div>
                    <div class="col-6" style="width: 100%; height: 300px;background-image: url(../../../assets/images2/gallery/image4.jpeg);background-size: cover;background-position: center;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>