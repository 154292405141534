import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { LanguageStoreService } from '../core/stores/language-store.service';
declare var gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  isLoading = true;
  title = 'apulia_surf_school';

  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-NZW4221YYT', { 'page_path': event.urlAfterRedirects });
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.isLoading = false;
    }, 800); 
  }

}
