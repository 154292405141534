<nav class="navbar navbar-expand-lg navbar-light fixed-top py-3 " id="mainNav">
    <div class="container px-4 px-lg-5">
        <img class="navbar-brand d-sm-none d-md-none d-lg-block d-xl-block" [src]="languageStoreService.Texts.navbar.logo" width="70" height="90" style="filter: brightness(0) invert(1);">
        <img class="navbar-brand d-sm-block d-md-block d-lg-none d-xl-none" [src]="languageStoreService.Texts.navbar.logo" width="70" height="90" >
        <button class="navbar-toggler navbar-toggler-right" type="button" data-bs-toggle="collapse"
            data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
            aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
            <ul class="navbar-nav ms-auto my-2 my-lg-0">
                <li class="nav-item">
                    <a class="nav-link" href="#page-top" (click)="changeActiveMenu('pageTop')">
                        <i class="bi bi-tsunami fs-7 text-primary" *ngIf="activeMenu == 'pageTop'"></i>
                        {{languageStoreService.Texts.navbar.home }}
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#about" (click)="changeActiveMenu('about')">
                        <i class="bi bi-tsunami fs-7 text-primary" *ngIf="activeMenu == 'about'"></i>
                        {{ languageStoreService.Texts.navbar.about }}
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#services" (click)="changeActiveMenu('lessons')">
                        <i class="bi bi-tsunami fs-7 text-primary" *ngIf="activeMenu == 'lessons'"></i>
                        {{ languageStoreService.Texts.navbar.classes }}
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#price" (click)="changeActiveMenu('services')">
                        <i class="bi bi-tsunami fs-7 text-primary" *ngIf="activeMenu == 'services'"></i>
                        {{ languageStoreService.Texts.navbar.price }}
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#contact" (click)="changeActiveMenu('contact')">
                        <i class="bi bi-tsunami fs-7 text-primary" *ngIf="activeMenu == 'contact'"></i>
                        {{ languageStoreService.Texts.navbar.contact }}
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link hovercursor">
                        <img [src]="selectImage" width="20" height="15" (click)="value == 'pt' ? changeLanguage('eng') : changeLanguage('pt') " />
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>

