<section class="page-section" id="map_section">
    <div class="row gx-4 gx-lg-5 justify-content-center">
        <div class="col-lg-8 col-xl-6 text-center">
            <h2 class="mt-0">{{ languageStoreService.Texts.map.title }}</h2>
            <div class="project-name text-black-50 mb-md-5" style="letter-spacing: 0.25em;text-align:center">{{
                languageStoreService.Texts.map.subTitle }}</div>
        </div>
    </div>
    <div class="row gx-4 gx-lg-5 justify-content-center">
        <div class="col-lg-12">
            <div id="map" style="height:500px;width: 100%;background-color: white;"
                class="d-sm-none d-md-none d-lg-block d-xl-block"></div>
            <div id="map_mobile" class="d-sm-block d-md-block d-lg-none d-xl-none mt-4"
                style="height:600px;width: 100%;"></div>
        </div>
    </div>
</section>