<section class="page-section py-0" id="price">
    <div class="container">
        <h1 class="mb-4" style="letter-spacing: 0.25em;text-align:center"> {{ languageStoreService.Texts.prices.title }}
        </h1>
        <div class="row mb-md-5">
            <ng-container *ngFor="let item of languageStoreService.Texts.prices.prices; index as i">
                <div class="col-lg-5 mt-md-5 mb-md-5 d-sm-none d-md-none d-lg-block d-xl-block">
                    <div class="row bg-secondary" style="height: 270px;">
                        <div class="portfolio-box-caption"
                            style="align-items: baseline;padding-left: 20px;justify-content: flex-start;padding-top: 25px;">
                            <div class="row" *ngIf="item.rigth">
                                <div class="col-7 align-items-center justify-content-center text-center">
                                    <div class="project-name fs-1">{{item.title}}</div>
                                    <h4 class="text-muted mb-0">{{item.subTitle}}</h4>
                                    <div class="row">
                                        <div class="col" style="align-self: center;">
                                            <ul class="list-group list-group-flush">
                                                <li class="list-group-item bg-secondary text-primary"
                                                    *ngFor="let price of item.priceList">
                                                    {{price}}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="row  align-items-center justify-content-center text-center">
                                        <div class="col mt-md-3">
                                            <div class="btn-wave hovercursor">
                                                <a data-bs-toggle="modal" data-bs-target="#reservationModal">
                                                    <span>{{item.btn}}</span>
                                                    <div class="wave"></div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div [ngStyle]="{'background-image': 'url(' + item.image + ')'}" class="imageBox">
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="!item.rigth">
                                <div class="col-4">
                                    <div [ngStyle]="{'background-image': 'url(' + item.image + ')'}" class="imageBox2">
                                    </div>
                                </div>
                                <div class="col-7 align-items-center justify-content-center text-center">
                                    <div class="project-name fs-1">{{item.title}}</div>
                                    <h4 class="text-muted mb-0">{{item.subTitle}}</h4>
                                    <div class="row">
                                        <div class="col" style="align-self: center;">
                                            <ul class="list-group list-group-flush">
                                                <li class="list-group-item bg-secondary text-primary"
                                                    *ngFor="let price of item.priceList">
                                                    {{price}}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="row  align-items-center justify-content-center text-center">
                                        <div class="col mt-md-3">
                                            <div class="btn-wave hovercursor">
                                                <a data-bs-toggle="modal" data-bs-target="#reservationModal">
                                                    <span>{{item.btn}}</span>
                                                    <div class="wave"></div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 mb-2 d-sm-block d-md-block d-lg-none d-xl-none">
                    <div class="row bg-secondary" style="height: 270px;">
                        <div class="portfolio-box-caption"
                            style="align-items: baseline;padding-left: 20px;justify-content: flex-start;padding-top: 25px;">
                            <div class="row">
                                <div class="col-12 align-items-center justify-content-center text-center">
                                    <div class="project-name fs-1">{{item.title}}</div>
                                    <h4 class="text-muted mb-0">{{item.subTitle}}</h4>
                                    <div class="row">
                                        <div class="col" style="align-self: center;">
                                            <ul class="list-group list-group-flush">
                                                <li class="list-group-item bg-secondary text-primary"
                                                    *ngFor="let price of item.priceList">
                                                    {{price}}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="row  align-items-center justify-content-center text-center">
                                        <div class="col mt-3">
                                            <div class="btn-wave hovercursor">
                                                <a data-bs-toggle="modal" data-bs-target="#reservationModal">
                                                    <span>{{item.btn}}</span>
                                                    <div class="wave"></div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2" *ngIf="item.rigth">
                </div>
            </ng-container>
        </div>
    </div>
</section>